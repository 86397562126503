<template>
  <v-menu
    max-width="470px"
    transition="slide-x-transition"
    origin="top center"
    right
  >
    <template #activator="{ on }">
      <v-input
        filled
        hide-details
        :class="`text-subtitle-1 d-flex flex-column px-2 rounded-t grey lighten-3 ${inputClass}`"
        style="border-bottom: grey solid 1px !important;"
        height="56px"
      >
        <div style="width: 100%" class="d-flex flex-column">
          <span class="white grey--text text--darken-2 px-1 text-caption grey lighten-3">
            {{ label }}
          </span>
          <div class="d-block px-2">
            <v-btn
              small
              block
              depressed
              :color="value"
              v-on="on"
              :disabled="disabled"
            />
          </div>
        </div>
      </v-input>
    </template>
    <v-card class="pa-2" filled >
      <v-color-picker
        :value="value"
        @input="$emit('input', $event)"
        hide-canvas
        hide-inputs
        hide-sliders
        show-swatches
        width="100%"
        swatches-max-height="100px"/>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ColorPicker",
  props: {
    value: {required: true, type: String},
    label: {required: true, type: String},
    inputClass: {required: false, default: ""},
    disabled: {required: false, default: false},
  },
}
</script>
